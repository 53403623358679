import PropTypes from "prop-types";
import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import { Helmet } from "react-helmet";

if (typeof document !== `undefined`) {
  const Klaro = require("klaro");
  const klaroConfig = require("../assets/js/klaro-config").default;
  window.klaro = Klaro;
  window.klaroConfig = klaroConfig;
  Klaro.setup(klaroConfig);
}

function Layout({ children, isSinglePage }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "de",
        }}
      >
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="matomo"
        >
          {`
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//analytics.webwerker.io/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
  `}
        </script>
      </Helmet>
      <div className="scrollzipPoint" id="scrollzipPoint"></div>

      <Navigation isSinglePage={isSinglePage} />

      <>{children}</>

      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isSinglePage: PropTypes.bool,
};

export default Layout;
