import * as React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="bg-gray-100">
      <section id="footer">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <ul>
            <li>
              <Link to="/datenschutz">Datenschutzerklärung</Link>
            </li>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
          </ul>
          &copy; {new Date().getFullYear()} web/werker
        </div>
      </section>
    </footer>
  );
};

export default Footer;

/*
<ul>
            <li>
              <Link to="/agb">AGB</Link>
            </li>
          </ul>
          */
