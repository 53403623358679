import React from "react"
import { Link } from "react-scroll"
import PropTypes from "prop-types"
import { Link as AHref } from "gatsby"

const Navigation = ({ isSinglePage = false }) => {
  const [myBgClass, setMyBgClass] = React.useState("bg-opacity-0")
  const [myTextClass, setMyTextClass] = React.useState("text-white")
  const [showMenu, setShowMenu] = React.useState(false)
  const [myBtnClass, setMyBtnClass] = React.useState("bg-white")

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const setClassesTop = () => {
    setMyBgClass("bg-opacity-0 border-0")
    setMyTextClass("text-white")
    setMyBtnClass("bg-white")
  }

  const setClassesScrolled = () => {
    setMyBgClass("bg-opacity-100 border-b")
    setMyTextClass("text-black")
    setMyBtnClass("bg-black")
  }

  function setClasses() {
    if (isSinglePage) {
      setClassesScrolled()
    } else {
      window.scrollY < 60 ? setClassesTop() : setClassesScrolled()
    }
  }

  function printLinks() {
    return [
      {
        route: `about`,
        title: `Über`,
      },
      {
        route: `services`,
        title: `Services`,
      },
      {
        route: `contact`,
        title: `Kontakt`,
      },
      {
        route: `blog`,
        title: `Blog`,
        useAbsoluteUrl: true,
      },
    ].map(link => (
      <li key={link.title}>
        {link.useAbsoluteUrl ? (
          <AHref
            to={`/${link.route}`}
            className={`nav-link p-2 block`}
            activeClassName="active"
            onClick={toggleMenu}
          >
            {link.title}
          </AHref>
        ) : isSinglePage ? (
          <AHref
            to={`/#${link.route}`}
            activeClassName="active"
            className={`nav-link p-2 block`}
            onClick={toggleMenu}
          >
            {link.title}
          </AHref>
        ) : (
          <Link
            activeClass="active"
            to={link.route}
            className={`nav-link p-2 block`}
            spy={true}
            smooth={true}
            duration={500}
            onClick={toggleMenu}
          >
            {link.title}
          </Link>
        )}
      </li>
    ))
  }

  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", setClasses)
    }

    watchScroll()

    setClasses()

    return () => window.removeEventListener("scroll", setClasses)
  })

  return (
    <div
      className={`fixed left-0 top-0 w-full z-50 transition-all duration-300 ease-out bg-white ${myBgClass} ${myTextClass}`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className={`flex-shrink-0 flex items-center logo`}>
            <AHref to="/" className="logo-link">
              <span>web</span>werker
            </AHref>
          </div>

          <nav className="flex">
            <button
              type="button"
              className="block sm:hidden px-3 -mt-2"
              onClick={toggleMenu}
            >
              <span className={`icon-bar ${myBtnClass}`}></span>
              <span className={`icon-bar ${myBtnClass}`}></span>
              <span className={`icon-bar ${myBtnClass}`}></span>
            </button>

            {showMenu && (
              <div className="absolute top-20 left-0 right-0 bg-white flex w-full p-2 sm:hidden">
                <ul className="text-black">{printLinks()}</ul>
              </div>
            )}

            <ul className="hidden sm:flex items-center">{printLinks()}</ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  isSinglePage: PropTypes.bool,
}

export default Navigation
